<template>
  <div class="list-page">
    <list-layout
      ref="listLayout"
      :filter-form="filterForm"
      :thead="thead"
      :tab-arr="tabArr"
      :on-fetch="getList"
      :on-put-back="handlePutBack"
    >
      <template v-slot:filter>
        <list-filter
          :filterForm="filterForm"
          :uploadFilter="refreshList"
        ></list-filter>
      </template>
      <template #tabletop></template>
      <template slot="item_name" slot-scope="{ row }">
        <div class="goods-name">
          <div class="left">
            <!-- 默认显示第一个商品规格 -->
            <list-image
              v-if="row.orderGoods[0]"
              fit="cover"
              :width="80"
              border-radius="5%"
              :src="row.orderGoods[0].image"
            />
          </div>
          <div class="right">
            <div class="goods-attr">{{ row.orderGoods[0].name }}</div>
            <div class="goods-specs">
              {{ Object.values(row.orderGoods[0].specs.attrs).join(";") }}
            </div>
            <div class="more" v-if="row.orderGoods.length > 1 ">
              <el-popover placement="right-end" trigger="hover">
                <el-table :data="row.orderGoods" max-height="300px">
                  <el-table-column
                    min-width="250"
                    prop="specs"
                    label="商品名称/属性"
                  >
                    <template slot-scope="{ row }">
                      <div class="goods-name">
                        <div class="left">
                          <!-- 默认显示第一个商品规格 -->
                          <list-image
                            v-if="row"
                            fit="cover"
                            :width="80"
                            border-radius="5%"
                            :src="row.image"
                          />
                        </div>
                        <div class="right">
                          <div class="goods-attr">
                            {{ row.name }}
                          </div>
                          <div class="goods-specs">
                            {{ Object.values(row.specs.attrs).join(";") }}
                          </div>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="150"
                    prop="price"
                    label="单价数量"
                  >
                    <template slot-scope="{ row }">
                      <div class="price_count">
                        <div v-if="row.price>0" class="price">￥{{ row.price }}</div>
                        <div v-if="row.price>0&&row.integral>0" class="price">{{ '+' }}</div>
                        <div v-if="row.integral>0" class="price">{{ row.integral }}积分</div>
                        <div class="count">x {{ row.amount }}</div>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <el-link type="primary" slot="reference" :underline="false"
                  >显示更多</el-link
                >
              </el-popover>
            </div>
          </div>
        </div>
      </template>
      <template slot="item_count" slot-scope="{ row }">
        <div class="price_count">
          <div v-if="row.orderGoods[0].price>0" class="price">￥{{ row.orderGoods[0].price }}</div>
          <div v-if="row.orderGoods[0].price>0 && row.orderGoods[0].integral>0" class="price">{{ '+' }}</div>
          <div v-if="row.orderGoods[0].integral>0" class="price">{{ row.orderGoods[0].integral }}积分</div>
          <div class="count">x {{ row.orderGoods[0].amount }}</div>
        </div>
      </template>

      <template slot="item_order_price" slot-scope="{ row }">
        <div class="order_price">
          <div class="price">￥{{ row.order_price.toFixed(2) }}</div>
          <div class="text">运费：￥{{ totalFreight(row.orderGoods) }}</div>
          <!-- 改价先隐藏 -->
          <div class="text" v-if="false">改价：-￥0.00</div>
        </div>
      </template>

      <template slot="item_user" slot-scope="{ row }">
        <div class="user">
          <div class="item">
            <div class="left">买家：</div>
            <div class="right">{{ row.user.nickname }}</div>
          </div>
          <div class="item">
            <div class="left">会内职务：</div>
            <div class="right">{{ row.user.title_name | placeholder }}</div>
          </div>
          <div class="item" v-if="row.remarks">
            <div class="left">备注：</div>
            <div class="right">
              {{ row.remarks | placeholder }}
              <el-link
                style="margin-left: 5px"
                :underline="false"
                type="primary"
                >修改</el-link
              >
            </div>
          </div>
        </div>
      </template>
      <template slot="item_status" slot-scope="{ row }">
        <list-status-text
          :type="
            row.status == 1
              ? 'warning'
              : row.status == 0
              ? 'success'
              : row.status == 2
              ? 'info'
              : 'danger'
          "
          :text="row.status_text"
        />
      </template>
      <template #top> </template>
      <template v-slot:operation="scope">
        <el-button type="text" size="small" @click="editRow(scope.row)"
          >详情</el-button
        >
        <!-- 改价先隐藏 -->
        <el-button
          v-if="scope.row.status == 1 && false"
          type="text"
          size="small"
          @click="offShelfRow(scope.row.id)"
          >改价</el-button
        >
        <el-button
          v-if="scope.row.receive_status == 0 && scope.row.status==0 && (scope.row.phone && scope.row.address)"
          type="text"
          size="small"
          @click="deliverRow(scope.row, 'normal')"
          >立即发货</el-button
        >
        <el-button
          v-if="scope.row.receive_status == 1 && scope.row.status ===0"
          type="text"
          size="small"
          @click="deliverRow(scope.row, 'edit')"
          >修改发货信息</el-button
        >
      </template>
    </list-layout>
    <DeliverGoodsDialog
      :currentItem="currentItem"
      v-model="openDelvier"
      :goodsType="goodsType"
      @refresh="refreshList"
    ></DeliverGoodsDialog>
  </div>
</template>

<script>
import ListStatusText from "@/base/components/List/ListStatusText";
import ListLayout from "@/base/layout/ListLayout";
import ListFilter from "../components/ShopOrder/ListFilter";
import ListImage from "@/base/components/List/ListImage";
import DeliverGoodsDialog from "../components/ShopOrder/DeliverGoodsDialog";

import { getDataList, putBack } from "../api/order-list";
export default {
  components: {
    ListFilter,
    ListLayout,
    ListStatusText,
    ListImage,
    DeliverGoodsDialog,
  },
  data() {
    return {
      tabArr: [
        { label: "全部", name: "all" },
        { label: "待付款", name: "waite_pay" },
        { label: "待发货", name: "waite_deliver", badge: 1  },
        { label: "已发货", name: "delivered" },
        { label: "已完成", name: "completed" },
        { label: "已取消", name: "cancel" },
      ],
      thead: [
        //   { type: "selection" },
        { label: "订单号", prop: "orderid", minWidth: 100 },
        { label: "商品名称/属性", prop: "name", minWidth: 300, type: "slot" },
        {label: '支付类型', prop: 'pay_type_text',},
        {
          label: "单价/数量",
          prop: "count",
          minWidth: 120,
          type: "slot",
        },
        {
          label: "实收款",
          prop: "order_price",
          minWidth: 120,
          type: "slot",
        },
        {
          label: "买家沟通",
          prop: "user",
          minWidth: 200,
          type: "slot",
        },
        {
          label: "商户号",
          prop: "merchant",
          minWidth: 120,
        },
        {
          label: "订单状态",
          prop: "status",
          minWidth: 120,
          type: "slot",
        },
        {
          label: "下单时间",
          prop: "create_time",
          minWidth: 200,
          sortable: true,
        },
        { label: "操作", type: "operation", minWidth: 180 },
      ],
      // 筛选对象
      filterForm: {
        tab: "all",
        name: "", // 商品名
        status: -1, // 全部
        user_name: "", // 用户名
        merchant: "", // 商户号
        order_by: "", // 排序字段
        is_desc: 1, // 正序还是倒序
        min_price: "", // 最低价
        max_price: "", // 最高价
        category_id: "", // 分类id
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        page_size: 15, //每页多少条数据
      },
      currentItem: {},
      // 发货弹窗
      openDelvier: false,
      // 发货弹窗类型
      goodsType: "normal",
    };
  },
  methods: {
    getList(data) {
      return getDataList(data);
    },
    // 筛选回调
    ok(e) {
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.$refs.listLayout.getList(this.filterForm);
    },
    // 刷新列表
    refreshList() {
      this.$refs.listLayout.getList();
    },
    // 查看详情
    editRow(row) {
      this.currentItem = row;
      this.$router.push({
        name: "GoodsOrderDetail",
        params: {
          id: row.orderid,
        },
      });
    },
    // 批量上架
    shelfArr() {
      const selectArr = this.$refs.listLayout.selectArr;
      this.shelfRow(selectArr);
    },
    // 发货弹窗
    deliverRow(row, type) {
      this.goodsType = type;
      this.currentItem = row;
      this.openDelvier = true;
    },

    // 批量下架
    offShelfArr() {
      const selectArr = this.$refs.listLayout.selectArr;
      this.offShelfRow(selectArr);
    },
    // 下架
    offShelfRow(id) {
      changeStatus({ id, status: 2 }).then((res) => {
        this.$message.success("下架成功");
        this.refreshList();
      });
    },
    // 批量还原
    putbackArr() {
      const selectArr = this.$refs.listLayout.selectArr;
      if (selectArr.length > 0) {
        this.$msgbox
          .confirm("确定是否还原", "提示", {
            type: "success",
            confirmButtonText: "确定",
            cancelButtonText: "取消",
          })
          .then((res) => {
            putBack({ id: selectArr })
              .then((res) => {
                this.$message.success(res.msg);
                this.refreshList();
              })
              .catch((err) => {});
          })
          .catch((err) => console.log(err));
      } else {
        this.$message.info("没有检测到勾选数据");
      }
    },
    // 还原数据
    handlePutBack(data) {
      return putBack({ id: data });
    },
 
    // 新增商品
    addGoods() {
      // 跳转商品详情
      this.$router.push({
        name: "GoodsDetail",
        params: {
          id: 0,
        },
      });
    },
    // 计算订单商品总和运费
    totalFreight(goods) {
      let count = goods.reduce((prev, cur, index, arr) => {
        return prev + cur.postage_price;
      }, 0);
      return count.toFixed(2);
    },
  },

  computed: {
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
  },
};
</script>

<style lang="scss" scoped>
.goods-name {
  display: flex;
  height: 80px;
  .left {
    margin-right: 10px;
  }

  .right {
    .goods-attr {
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .goods-specs {
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .more {
      el-button {
      }
    }
  }
}
.price_count {
  height: 80px;
  .price {
  }

  .count {
  }
}
.user {
  .item {
    display: flex;
    .left {
      min-width: 60px;
      margin-right: 5px;
    }

    .right {
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.order_price {
  .price {
    font-weight: 700;
  }

  .text {
    color: #b3b3b3;
    font-size: 12px;
  }
}
</style>
